import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import Icon from "@base/components/Global/Icon";
import { RichListTranslation } from "@base/components/Global/RichTextTranslation";
import { useRichTextTranslation } from "@base/components/Global/RichTextTranslation/useRichTextTranslation";
import useSalesPointTranslationKey from "@base/hooks/useSalesPointTranslationKey";
import { useWhiteLabelImage } from "@base/hooks/useWhiteLabelImage";
import { getImageFromAPI } from "@base/utils/imageHelper";
import { getBlobStorage } from "@theme";

const useStyles = createUseStyles(({ spacing, font, color, mediaQuery }) => ({
  container: {
    display: "flex",
    minHeight: 0,
    padding: spacing.x4l,
    background: color.modalBg,
    [mediaQuery.md]: {
      width: 736,
      maxHeight: 520,
    },
    [mediaQuery.lg]: {
      width: 1000,
      maxHeight: 706,
    },
  },
  left: {
    gap: spacing.xxl,
    display: "flex",
    flexDirection: "column",
    [mediaQuery.md]: {
      minWidth: 321,
    },
    [mediaQuery.lg]: {
      minWidth: 585,
    },
  },
  image: {
    borderRadius: 24,
    width: "100%",
    height: 402,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  map: {
    height: 227,
    width: "100%",
    borderRadius: 24,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  contact: {
    padding: [0, spacing.xxl],
    display: "flex",
    flexDirection: "column",
    gap: spacing.x4l,
  },
  location: {
    fontWeight: font.weight.m,
    lineHeight: font.lineHeight.m,
    display: "flex",
    marginRight: spacing.s,
    color: color.locationText,
  },
  iconWrapper: {
    alignSelf: "flex-start",
    marginRight: spacing.s,
  },
  description: {
    fontWeight: font.weight.m,
    lineHeight: font.lineHeight.m,
    fontSize: font.size.s,
    display: "flex",
    color: color.locationDescription,
    overflowY: "auto",
  },
  header: {
    marginTop: 40,
    fontWeight: font.weight.s,
    fontSize: font.size.x3l,
    lineHeight: font.lineHeight.xl,
    color: color.locationInfoHeader,
  },
}));

const LocationModalContent: React.FC = () => {
  const classes = useStyles();
  const { locationIcon, locationMap, businessBanner } = getBlobStorage();
  const getWhiteLabeledImage = useWhiteLabelImage();
  const { resolveKey } = useSalesPointTranslationKey();
  const { t } = useTranslation("salespoint");
  const transProps = useRichTextTranslation();

  const mapImage = getWhiteLabeledImage(locationMap);
  const bannerImage = getWhiteLabeledImage(businessBanner);

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <div
          className={classes.image}
          style={{
            backgroundImage: `url(${getImageFromAPI(
              bannerImage || "",
              800,
              490,
            )})`,
          }}
        ></div>
        <a
          className={classes.map}
          style={{
            backgroundImage: `url(${getImageFromAPI(
              mapImage || "",
              1001,
              554,
            )})`,
          }}
          href={t(resolveKey("mapUrl"))}
          target="_blank"
        />
      </div>
      <div className={classes.contact}>
        <span className={classes.header}>{t(resolveKey("name"))}</span>
        <div className={classes.location}>
          <div className={classes.iconWrapper}>
            <Icon url={locationIcon} height={24} width={24} />
          </div>
          <div>
            <RichListTranslation
              getTranslation={() =>
                t(resolveKey("addressLines", { returnObjects: true }), {
                  returnObjects: true,
                })
              }
            />
            <RichListTranslation
              getTranslation={() =>
                t(resolveKey("contacts", { returnObjects: true }), {
                  returnObjects: true,
                })
              }
            />
          </div>
        </div>
        <div className={classes.description}>
          <Trans
            {...transProps}
            i18nKey={resolveKey("description")}
            ns="salespoint"
          />
        </div>
      </div>
    </div>
  );
};

export default LocationModalContent;
