import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import useSalesPointTranslationKey from "@base/hooks/useSalesPointTranslationKey";
import { getImageFromAPI } from "@base/utils/imageHelper";
import { ABOVE_FOLD_HEIGHT } from "@constants/misc";
import { getStorage, setStorage, StorageKey } from "@constants/storage";
import classNames from "classnames";
import { isToday } from "date-fns";

const BACKGROUND_POSITION_Y_PERCENTAGE = 50;

type StyleProps = {
  shouldCompensateBottom: boolean;
};

const useStyles = createUseStyles(({ color, font }) => ({
  landingImageContainer: {
    height: "100vw",
    width: "100%",
    overflow: "hidden",
    position: "relative",
    transition: "height 800ms cubic-bezier(0.63, -0.14, 0.34, 1.18)",
    marginBottom: ({ shouldCompensateBottom }: StyleProps) =>
      shouldCompensateBottom ? -100 : "initial",
  },
  bgImage: {
    width: "100%",
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    zIndex: -1,
    backgroundPositionY: `${BACKGROUND_POSITION_Y_PERCENTAGE}%`,
    backgroundPosition: "center",
    backgroundColor: color.landingImageBg,
  },
  bgImageTopGradientOverlay: {
    background: color.topNavBarBgImgTopGradient,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    height: "100%",
  },
  bgImageBottomGradientOverlay: {
    background: color.topNavBarBgImgBottomGradient,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    height: "100%",
  },
  locationTitle: {
    top: 60,
    left: 32,
    position: "absolute",
    fontSize: 44,
    fontWeight: font.weight.l,
    lineHeight: font.lineHeight.xxl,
    wordSpacing: 9999,
    color: color.brandLocationTitle,
    transition: "opacity 400ms ease-in-out",
  },
  locationTitleHidden: {
    opacity: 0,
  },
}));

type LandingImageProps = {
  shouldDelayContraction?: boolean;
  hasTitle?: boolean;
  hasTopGradient?: boolean;
  hasBottomGradient?: boolean;
  shouldCompensateBottom?: boolean;
  imageUrl: string | undefined;
};

const LandingImage: React.FC<LandingImageProps> = ({
  shouldDelayContraction = false,
  hasTitle = false,
  hasTopGradient = false,
  hasBottomGradient = false,
  shouldCompensateBottom = false,
  imageUrl = "",
}) => {
  const classes = useStyles({ shouldCompensateBottom });
  const { t } = useTranslation("salespoint");
  const { resolveKey } = useSalesPointTranslationKey();
  const [isTitleVisible, setIsTitleVisible] = useState(true);
  const bgImageContainerRef = useRef<HTMLDivElement>(null);

  const lastLandingStorageItem = getStorage(StorageKey.LAST_LANDING);

  const hasLandedToday =
    lastLandingStorageItem &&
    isToday(new Date(parseInt(lastLandingStorageItem)));

  const [isExpanded, setIsExpanded] = useState(
    shouldDelayContraction && !hasLandedToday,
  );

  const performContraction = () => {
    setIsExpanded(false);
    setIsTitleVisible(false);
  };

  useEffect(() => {
    const scrollCallback = () => {
      if (!bgImageContainerRef.current || window.scrollY > ABOVE_FOLD_HEIGHT)
        return;

      if (shouldDelayContraction) performContraction(); // Perform the contraction early if the user scrolls

      const bgPositionYModifier =
        BACKGROUND_POSITION_Y_PERCENTAGE - window.scrollY / 32;

      bgImageContainerRef.current.style.backgroundPositionY = `${bgPositionYModifier.toString()}%`;
    };

    window.addEventListener("scroll", scrollCallback);

    return () => window.removeEventListener("scroll", scrollCallback);
  }, []);

  useEffect(() => {
    if (!shouldDelayContraction || hasLandedToday) {
      return;
    }

    setStorage(StorageKey.LAST_LANDING, Date.now().toString());

    const contractionTimer = setTimeout(() => {
      setIsExpanded(false);
    }, 2500);

    const titleTimer = setTimeout(() => {
      setIsTitleVisible(false);
    }, 3500);

    return () => {
      clearTimeout(contractionTimer);
      clearTimeout(titleTimer);
    };
  }, []);

  return (
    <>
      <div
        className={classes.landingImageContainer}
        style={{
          height: isExpanded ? "100vh" : "50vh",
        }}
      >
        <div
          ref={bgImageContainerRef}
          className={classNames([classes.bgImage])}
          style={{
            backgroundImage: `url(${getImageFromAPI(
              imageUrl,
              1290,
              shouldDelayContraction ? 2796 : 1290,
            )})`,
          }}
        >
          {hasTopGradient && (
            <div className={classes.bgImageTopGradientOverlay}></div>
          )}
          {hasBottomGradient && (
            <div className={classes.bgImageBottomGradientOverlay}></div>
          )}
        </div>
        {hasTitle ? (
          <div
            className={classNames([
              classes.locationTitle,
              { [classes.locationTitleHidden]: !isTitleVisible },
            ])}
          >
            {t(resolveKey("name"))}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default LandingImage;
