import React from "react";
import { createUseStyles } from "react-jss";
import classNames from "classnames";

const useStyles = createUseStyles(
  ({ borderRadius, mediaQuery, color, spacing, font }) => ({
    container: {
      borderRadius: borderRadius.x3l,
      display: "flex",
      padding: spacing.x4l,
      gap: spacing.x3l,
    },
    SUCCESS: {
      background: color.bannerSuccessBackground,
      color: color.bannerSuccessTextColor,
    },
    ERROR: {
      background: color.bannerErrorBackground,
      color: color.bannerErrorTextColor,
    },
    columnVariant: {
      flexDirection: "column",
    },
    column: {
      flex: "1",
      display: "flex",
      alignItems: "center",
    },
    textColumn: {
      flexDirection: "column",
      alignItems: "initial",
    },
    imageColumn: {
      flexGrow: 0,
    },

    title: {
      fontWeight: font.weight.s,
      fontSize: font.size.x4l,
      lineHeight: font.lineHeight.xxl,
      margin: [spacing.m, 0],
    },
    subtitle: {
      fontWeight: font.weight.m,
      lineHeight: font.lineHeight.m,
      fontSize: font.size.m,
      // margin: [spacing.m, 0],
      display: "block",
    },
    image: {
      objectFit: "cover",
      width: 100,
      height: 100,
      display: "none",
      [mediaQuery.md]: {},
      [mediaQuery.lg]: {
        display: "block",
      },
      [mediaQuery.xl]: {
        width: 150,
      },
      borderRadius: borderRadius.xl,
    },
  }),
);

enum BannerVariant {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

const Banner: React.FC<{
  title: string;
  subtitle: string;
  variant: BannerVariant;
  imgSrc?: string;
  column?: boolean;
  renderPayload?: () => JSX.Element;
}> = ({ title, subtitle, variant, imgSrc, renderPayload, column = false }) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.container, classes[variant], [
        column && classes.columnVariant,
      ])}
    >
      {imgSrc && (
        <div className={classNames(classes.column, classes.imageColumn)}>
          <img className={classes.image} src={imgSrc} />
        </div>
      )}
      <div className={classNames(classes.column, classes.textColumn)}>
        <h2 className={classes.title}>{title}</h2>
        <span className={classes.subtitle}>{subtitle}</span>
      </div>
      {renderPayload && <div className={classes.column}>{renderPayload()}</div>}
    </div>
  );
};

export { BannerVariant };
export default Banner;
