import { IProduct, IProductTax } from "@api/interfaces/productLayouts";
import { InferType, number, object, string } from "yup";
import * as z from "zod";

import { IDiningOptionEnum } from "./configurationLayouts";

export interface ICartModifierChoices {
  ProductModifierChoiceID: number;
  ProductModifierID: number;
  RelatedProductID: number;
  ProductModifierChoiceDisplayName: string;
  ProductModifierChoicePublicDescription: string;
  RelatedProductShoppingCartLineID: number;
  Quantity: number;
}

export interface IRelatedChildShoppingCartLines {
  ID: number;
}

export type TheatreResource = {
  ID: number;
  Name: string;
};

export interface ICartLineItem {
  ID: number;
  Name: string;
  Amount: number;
  GrossSum: number;
  GrossUnitPrice: number;
  NetSum: number;
  NetUnitPrice: number;
  CustomModifiers: string;
  Product: IProduct;
  Tax: IProductTax;
  ProductModifierChoices?: ICartModifierChoices[];
  RelatedShoppingCartLineID: number;
  RelatedChildShoppingCartLines?: IRelatedChildShoppingCartLines[];
  VariableProductID?: number;
  ProductPackagingSetConstraintID?: number;
  groupedIds?: number[];
  TheatreResource: TheatreResource;
}

export interface ICartTax {
  Sum: number;
  Tax: IProductTax;
}

export interface ISum {
  LineNetSum: number;
  LineGrossSum: number;
  Taxes: ICartTax[];
  LineTotalAmount: number;
}

export type PurchaseConfirmation = {
  Category: string;
  Email: string;
  Address: string;
};

export type CustomerPerson = {
  ID: number;
  FirstName: string;
  LastName: string;
  Status: number;
  Email: string;
  Mobile: string;
};

export interface ICartLayout {
  ID: number;
  BarcodeWithoutChecksum: string;
  ContactInfo: ICartContactInfo | null;
  Defaults: {
    DiningOptionID: IDiningOptionEnum;
    TheatreResourceID: number;
    dttmRequestedDelivery: string | null;
  };
  Status: CartStatus;
  Lines?: ICartLineItem[];
  Sums: ISum;
  PurchaseConfirmation: PurchaseConfirmation;
  CustomerPerson: CustomerPerson;
  dttmCreated: Date;
  dttmCreatedUTC: Date;
  dttmExpires: Date;
  dttmExpiresUTC: Date;
  dttmNowUTC: Date;
  CheckoutOptions: Array<ICheckoutOption>;
}

export enum EShoppingCartOnHoldReason {
  Draft = 2,
  Locked = 3,
  OpenInvoice = 4,
}

export enum ECheckoutOptionType {
  PayNow = 1,
  PayLater = 2,
}

export const ICustomFieldsAndValuesSchema = z.object({
  CustomFieldValueId: z.number().optional(),
  CustomFieldId: z.number().optional(),
  CustomFieldName: z.string().optional(),
  ObjectKeyId: z.number().optional(),
  ObjectInstanceId: z.number().optional(),
  CustomFieldDataTypeId: z.number().optional(),
  CustomFieldStatusId: z.number().optional(),
  CustomFieldValueStatusId: z.number().optional(),
  ObjectInstanceSubitemId: z.number().optional(),
  IsTranslatable: z.boolean().optional(),
  Preference: z.number().optional(),
  TextValue: z.string().optional(),
});

export type ICustomFieldsAndValues = {
  CustomFieldValueId?: number;
  CustomFieldId?: number;
  CustomFieldName?: string;
  ObjectKeyId?: number;
  ObjectInstanceId?: number;
  CustomFieldDataTypeId?: number;
  CustomFieldStatusId?: number;
  CustomFieldValueStatusId?: number;
  ObjectInstanceSubitemId?: number;
  IsTranslatable?: boolean;
  Preference?: number;
  TextValue?: string;
};

export const ICheckoutOptionSchema = z.object({
  ShoppingCartOnHoldReason: z.nativeEnum(EShoppingCartOnHoldReason),
  CheckoutOptionType: z.nativeEnum(ECheckoutOptionType),
  IsEnabled: z.boolean(),
  Title: z.string().optional(),
  CustomFieldsAndValues: z.array(ICustomFieldsAndValuesSchema).nullable(),
});
export type ICheckoutOption = z.infer<typeof ICheckoutOptionSchema>;

export interface ICartAddModifierChoice {
  ProductModifierID: number;
  ProductModifierChoiceID: number;
  Amount: number;
  ProductId?: number;
}

export interface ILineAdd {
  ProductID: number;
  Amount: number;
  ModifierChoices?: ICartAddModifierChoice[];
  CustomModifiers?: string;
  VariableProductID?: number;
}

export interface IAddLineToCartBody {
  Operation: number;
  Products: ILineAdd[];
}

export type AddProductToCartRequest = {
  transactionId: number;
  body: IAddLineToCartBody;
};

export interface ISetCartCustomer {
  CustomerPersonID: number;
  LoyaltyProgramMembershipID: number;
}

export const OrderStorageSchema = object({
  id: string().required(), // barcodeWithoutChecksum
  created: number().required(),
});
export type OrderStorage = InferType<typeof OrderStorageSchema>;

export enum CartStatus {
  UNKNOWN = 0,
  REGULAR = 1,
  ON_HOLD = 2,
}

export const ICartContactInfoSchema = object({
  Name: string().nullable(),
  Phone: string().nullable(),
  Email: string().nullable(),
});

export type ICartContactInfo = InferType<typeof ICartContactInfoSchema>;

export type GetShoppingCartByBarcodeRequest = {
  transactionId: number;
};

export type RemoveProductFromCartRequest = {
  productId: number;
  transactionId: number;
};

export type RemoveAllProductsFromCartRequest = {
  transactionId: number;
};

export type RemoveLineFromCartRequest = {
  cartLineIds: number[];
  transactionId: number;
};

export type BundleRequest = {
  transactionId: number;
};

export type UpdateCustomerCartRequest = {
  transactionId: number;
  body: ISetCartCustomer;
};

export type UnbundleRequest = {
  transactionId: number;
};

export type UpdateTheatreResourceRequest = {
  theatreResourceId: number;
  transactionId: number;
};

export type RemoveCustomerFromCartRequest = {
  transactionId: number;
};

export type BindContactInfoRequest = {
  transactionId: number;
  body: ICartContactInfo;
};

export type InitializeDiningCartRequest = {
  theatreResourceId: number;
  dttmRequestedDelivery: string | null;
  diningOptionId: IDiningOptionEnum;
  customerPersonId?: number;
};

export type UpdateLanguageRequest = {
  transactionId: number;
  body: { language: string };
};

export type PutOnHoldRequest = {
  transactionId: number;
  body: {
    ShoppingCartOnHoldReason: EShoppingCartOnHoldReason;
    Description: string;
  };
};
