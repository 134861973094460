import Payment from "@api/controller/payment";
import {
  IInitializePaymentResponse,
  IPaymentStatus,
} from "@api/interfaces/paymentLayouts";
import { ApiRequest, RequestResponse } from "@api/types";
import { NavigationPath, OrderID, PaymentStatus } from "@constants/navigation";
import { generatePath } from "react-router-dom";

type InitializePaymentOptions = {
  barcodeWithoutChecksum: string;
  returnLabel: string;
  confirmationEmail?: string | undefined;
  customerGUID: string | undefined;
};

const initializePayment: ApiRequest<
  RequestResponse<IInitializePaymentResponse>,
  InitializePaymentOptions
> = async ({
  barcodeWithoutChecksum,
  returnLabel,
  confirmationEmail,
  customerGUID = undefined,
  ...options
}) => {
  if (!barcodeWithoutChecksum) {
    throw new Error("No cart barcode without checksum found");
  }

  return Payment.initialize({
    ...options,
    barcodeWithoutChecksum,
    body: {
      transactionBarcode: barcodeWithoutChecksum,
      options: {
        customerGUID,
        returnActionLabel: returnLabel,
        confirmationEmail,
        returnURLSuccess: `${window.location.origin}${generatePath(
          NavigationPath.OrderPaymentCallback,
          {
            [OrderID]: barcodeWithoutChecksum,
            [PaymentStatus]: IPaymentStatus.SUCCESS,
          },
        )}`,
        returnURLCancelled: `${window.location.origin}${generatePath(
          NavigationPath.OrderPaymentCallback,
          {
            [OrderID]: barcodeWithoutChecksum,
            [PaymentStatus]: IPaymentStatus.CANCELLED,
          },
        )}`,
        returnURLError: `${window.location.origin}${generatePath(
          NavigationPath.OrderPaymentCallback,
          {
            [OrderID]: barcodeWithoutChecksum,
            [PaymentStatus]: IPaymentStatus.ERROR,
          },
        )}`,
        statusMonitorURL: `${window.location.origin}${generatePath(
          NavigationPath.OrderStatus,
          {
            [OrderID]: barcodeWithoutChecksum,
          },
        )}`,
      },
    },
  });
};

const PaymentService = {
  initializePayment,
};

export default PaymentService;
