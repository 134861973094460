import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { useNavigate } from "react-router-dom";
import { ICartAddModifierChoice } from "@api/interfaces/cartLayouts";
import {
  IModifier,
  ProductModifierConstraints,
} from "@api/interfaces/productLayouts";
import CartService from "@api/service/cartService";
import { isPresent } from "@apl-digital/utils";
import Box from "@base/components/Box/Box";
import BoxList from "@base/components/Box/BoxList";
import AmountButton from "@base/components/Cart/AmountBtn";
import ItemAddedSuccess from "@base/components/Cart/ItemAddedSuccess";
import Button from "@base/components/Global/Button";
import Footer from "@base/components/Global/Footer";
import Icon from "@base/components/Global/Icon";
import KitchenClosedFooterContent from "@base/components/Global/KitchenClosedFooterContent";
import Loader from "@base/components/Global/Loader";
import LandingImage from "@base/components/LandingImage/LandingImage";
import { useNavbarContext } from "@base/components/Navbar/NavbarContext";
import useInterval from "@base/hooks/useInterval";
import config from "@constants/config";
import { NavigationPath } from "@constants/navigation";
import DeleteItemsModal from "@pages/CartPage/components/DeleteItemsModal";
import { ErrorPage } from "@pages/ErrorPage";
import ExtrasBlock from "@pages/ProductPage/components/ExtrasBlock";
import ProductShapeBlock from "@pages/ProductPage/components/ProductShapeBlock";
import SpecialNeedsBlock from "@pages/ProductPage/components/SpecialNeedsBlock";
import { useAppDispatch, useAppSelector } from "@store";
import { useProducts } from "@store/ProductsProvider";
import {
  ProductListItem,
  ProductListItemType,
} from "@store/ProductsProvider/types";
import { selectIsKitchenOpen } from "@store/salesPoint/selectors";
import {
  addProductToCart,
  removeLinesFromCart,
  selectCanEditShoppingCart,
  selectCurrentTransactionId,
  selectShoppingCartTotalItemCount,
  selectShoppingCartUnifiedProducts,
} from "@store/shoppingCart";
import { isStatus } from "@store/utils";
import { getBlobStorage, getBrandTheme } from "@theme";
import classNames from "classnames";
import { deepEqual } from "fast-equals";

import { useProductParams } from "./hooks";
import {
  ProductPageContextProvider,
  useProductPageContext,
} from "./ProductPageContext";

const useStyles = createUseStyles(
  ({ font, spacing, color, sizes, config: themeConfig }) => ({
    itemTitle: {
      fontSize: font.size.x3l,
      fontWeight: font.weight.s,
      lineHeight: font.lineHeight.xl,
      color: color.productPageBoxTitle,
      paddingBottom: spacing.xs,
    },
    itemPrice: {
      fontSize: font.size.xl,
      fontWeight: font.weight.m,
      lineHeight: font.lineHeight.m,
      color: color.productPageBoxPrice,
      paddingTop: spacing.x4l,
      paddingBottom: spacing.xs,
    },
    itemDescription: {
      fontSize: font.size.m,
      paddingTop: spacing.x4l,
      lineHeight: font.lineHeight.m,
      fontWeight: font.weight.m,
      color: color.productPageBoxDescription,
    },
    buttonText: {
      flex: 1.2,
      width: "100%",
      maxWidth: 250,
      marginLeft: spacing.l,
      [sizes.smallWidth]: {
        fontSize: font.size.xxs,
      },
    },
    addToCart: {
      fontSize: font.size.s,
      lineHeight: font.lineHeight.xxs,
    },
    cartPrice: {
      fontSize: font.size.l,
      lineHeight: font.lineHeight.m,
      textAlign: "center",
    },
    warningContainer: {
      marginBottom: themeConfig.footerHeight,
    },
    warning: {
      flexBasis: "100%",
      color: color.warningBg,
      fontSize: font.size.s,
      lineHeight: font.lineHeight.s,
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      gap: spacing.xs,
    },
    infoBox: {
      padding: [spacing.x5l, spacing.x4l],
    },
    errorBtn: {
      color: color.white,
      backgroundColor: color.warningBg,
    },
    extrasBlockContainer: {
      marginBottom: spacing.x4l,
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  }),
);

type ProductPageContentProps = {
  currentProduct: ProductListItem;
};

const ProductPageContent: React.FC<ProductPageContentProps> = ({
  currentProduct,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("productPage");
  const urlParams = useProductParams();
  const navigate = useNavigate();
  const [, { getProductById }] = useProducts();

  const scrollList = useRef<HTMLDivElement>(null);
  const [modifiersPrice, setModifiersPrice] = useState<number>(0);
  const [shouldShowLoader, setShouldShowLoader] = useState(false);
  const [comment, setComment] = useState<string | undefined>(urlParams.comment);
  const [selectedSubItem, setSelectedSubItem] = useState<ProductListItem>();
  const [missingModifiers, setMissingModifiers] = useState<number[]>([]);
  const [hasModifiersError, setModifiersError] = useState<boolean>(false);
  const [cartModifiers, setCartModifiers] = useState<ICartAddModifierChoice[]>(
    [],
  );
  const [shouldShowSuccessModal, setShouldShowSuccessModal] =
    useState<boolean>(false);
  const [availableModifiers, setAvailableModifiers] = useState<
    IModifier[] | undefined
  >();

  const { setCustomTitle } = useNavbarContext();

  const { warningIcon } = getBlobStorage();
  const { color } = getBrandTheme();
  const [shouldShowDeleteModal, setShouldShowDeleteModal] = useState(false);
  const [navigateIndex, setNavigateIndex] = useState(-1);
  const dispatch = useAppDispatch();
  const transactionId = useAppSelector(selectCurrentTransactionId);
  const isKitchenOpen = useAppSelector(selectIsKitchenOpen);
  const canEditShoppingCart = useAppSelector(selectCanEditShoppingCart);

  const shoppingCartTotalOtherItemCount = useAppSelector((state) =>
    selectShoppingCartTotalItemCount(
      state,
      urlParams.isEdit ? [currentProduct?.id] : [],
    ),
  );

  const itemIds =
    currentProduct.type === ProductListItemType.VARIABLE
      ? currentProduct.subItems?.map((i) => i.ID) || []
      : [currentProduct.id];

  const productsInCart = useAppSelector((state) =>
    selectShoppingCartUnifiedProducts(state, itemIds),
  );
  const otherProductsInCart = productsInCart.filter(
    (product) =>
      !deepEqual(product.mergedCartLineIds, urlParams.shoppingCartLineIds),
  );
  const currentProductInCart = productsInCart.find((product) =>
    deepEqual(product.mergedCartLineIds, urlParams.shoppingCartLineIds),
  );

  const totalProductAmountInCart = productsInCart.reduce(
    (previousValue, currentValue) => previousValue + currentValue.amount,
    0,
  );

  const otherProductAmountInCart = otherProductsInCart.reduce(
    (previousValue, currentValue) => previousValue + currentValue.amount,
    0,
  );

  const currentProductAmountInCart = currentProductInCart?.amount || 0;

  const [currentProductAmount, setCurrentProductAmount] = useState<number>(
    urlParams.isEdit && isPresent(urlParams.amount) ? urlParams.amount : 1,
  );

  const navigateBack = (): void => {
    setShouldShowSuccessModal(false);
    navigate(navigateIndex);
  };

  useEffect(() => {
    setCustomTitle(currentProduct?.title);

    return () => {
      setCustomTitle(undefined);
    };
  });

  useEffect(() => {
    if (
      !urlParams.isEdit ||
      !isPresent(availableModifiers) ||
      urlParams.modifierChoiceIds.length < 0 ||
      availableModifiers.length < 0
    ) {
      return;
    }

    const addedChoices: ICartAddModifierChoice[] = [];

    availableModifiers.forEach((available) => {
      const allChoices = available.Choices.filter(
        (e) =>
          urlParams.modifierChoiceIds.includes(e.ProductID) ||
          urlParams.modifierChoiceIds.includes(e.ID),
      );

      allChoices.forEach((e) => {
        addedChoices.push({
          Amount: currentProductAmount,
          ProductModifierID: available.ID,
          ProductModifierChoiceID: e.ID,
          ProductId: e.ProductID,
        });
      });
    });

    setCartModifiers(addedChoices);
  }, [urlParams.isEdit, availableModifiers]);

  useInterval(navigateBack, 750, shouldShowSuccessModal);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // set selected subItem if item has any
  useEffect(() => {
    if (currentProduct) {
      if (currentProduct.subItems && currentProduct.subItems.length > 0) {
        if (urlParams.isEdit) {
          if (isPresent(urlParams.variableId)) {
            setSelectedSubItem(getProductById(urlParams.variableId));
          }
        } else {
          const defaultItem = currentProduct.subItems.find((i) => i.IsDefault);
          if (defaultItem) setSelectedSubItem(getProductById(defaultItem.ID));
          else
            setSelectedSubItem(getProductById(currentProduct.subItems[0].ID));
        }
      } else setSelectedSubItem(undefined);
    }
  }, [currentProduct, urlParams.isEdit]);

  // set product modifiers
  useEffect(() => {
    let modifiers: IModifier[] = [];
    if (selectedSubItem) {
      modifiers = selectedSubItem?.modifiers?.Modifiers || [];
    } else if (
      currentProduct &&
      currentProduct.type === ProductListItemType.ITEM
    ) {
      modifiers = currentProduct.modifiers?.Modifiers || [];
    }
    const sorted: IModifier[] = modifiers
      .slice()
      .sort((a, b) => (a.SortOrder <= b.SortOrder ? -1 : 1));
    setAvailableModifiers(sorted);
  }, [selectedSubItem, currentProduct]);

  const isModifiersChanged = useMemo(() => {
    const sortedCartModifierIDs = cartModifiers
      .map((m) => m.ProductModifierChoiceID)
      .sort();

    return !deepEqual(urlParams.modifierChoiceIds, sortedCartModifierIDs);
  }, [cartModifiers, urlParams.modifierChoiceIds]);

  const isQuantityChange = currentProductAmount !== urlParams.amount;
  const isCommentChanged = comment !== urlParams.comment;
  const isSubItemChanged = selectedSubItem?.id !== urlParams.variableId;

  const isChanged =
    isModifiersChanged ||
    isQuantityChange ||
    isCommentChanged ||
    isSubItemChanged;

  let price = 0;

  if (selectedSubItem && selectedSubItem.grossPrice) {
    price =
      selectedSubItem.grossPrice * currentProductAmount +
      modifiersPrice * currentProductAmount;
  } else if (currentProduct.grossPrice) {
    price =
      currentProduct.grossPrice * currentProductAmount +
      modifiersPrice * currentProductAmount;
  }

  const shoppingCartTotalItemCount =
    shoppingCartTotalOtherItemCount + currentProductAmount;

  const hasReachedMaxQtt =
    config.appConfig.singleItemMaxQuantity < currentProductAmount ||
    config.appConfig.singleItemMaxQuantity < totalProductAmountInCart ||
    config.appConfig.cartItemMaxQuantity < shoppingCartTotalItemCount ||
    (!urlParams.isEdit &&
      config.appConfig.singleItemMaxQuantity <
        totalProductAmountInCart + currentProductAmount);

  const onDeleteConfirm = () => {
    if (shoppingCartTotalItemCount > 0) navigateBack();
    else navigate(NavigationPath.Home);
  };

  const addToCart = (amount: number) => {
    if (!isPresent(transactionId)) {
      return;
    }

    setShouldShowLoader(true);

    const addRequest = CartService.buildAddItemRequest({
      productId: selectedSubItem ? selectedSubItem.id : currentProduct.id,
      amount: amount,
      modifiers: cartModifiers,
      noteToKitchen: comment,
      variableProductID: selectedSubItem ? currentProduct.id : undefined,
    });

    dispatch(addProductToCart({ transactionId, body: addRequest }))
      .then(() => {
        setShouldShowLoader(false);
        setShouldShowSuccessModal(true);
      })
      .finally(() => {
        setShouldShowLoader(false);
      });
  };

  const removeFromCart = (amount: number) => {
    if (
      !urlParams.isEdit ||
      !isPresent(urlParams.shoppingCartLineIds) ||
      !isPresent(transactionId)
    ) {
      return;
    }

    setShouldShowLoader(true);

    const cartLineIdsToRemove = urlParams.shoppingCartLineIds.slice(
      urlParams.shoppingCartLineIds.length - amount,
    );

    dispatch(
      removeLinesFromCart({
        transactionId,
        cartLineIds: cartLineIdsToRemove,
      }),
    )
      .then(() => {
        setShouldShowLoader(false);
        setShouldShowSuccessModal(true);
      })
      .finally(() => {
        setShouldShowLoader(false);
      });
  };

  const updateProductAll = () => {
    if (!urlParams.isEdit || !isPresent(transactionId)) {
      return;
    }

    setShouldShowLoader(true);

    dispatch(
      removeLinesFromCart({
        transactionId,
        cartLineIds: urlParams.shoppingCartLineIds,
      }),
    ).then(() => {
      addToCart(currentProductAmount);
    });
  };

  const updateProductQuantityOnly = () => {
    if (currentProductAmount > currentProductAmountInCart) {
      addToCart(currentProductAmount - currentProductAmountInCart);
    } else if (currentProductAmount < currentProductAmountInCart) {
      removeFromCart(currentProductAmountInCart - currentProductAmount);
    }
  };

  const updateProduct = () => {
    if (isModifiersChanged || isCommentChanged || isSubItemChanged) {
      updateProductAll();
    } else if (isQuantityChange) {
      updateProductQuantityOnly();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const scrollTop = document.documentElement.scrollTop;
      if (currentProduct && scrollTop === 0) {
        const boxListContainer = document.getElementById(
          `boxlist-${currentProduct.id}`,
        );
        const yPos = boxListContainer?.getBoundingClientRect().top;
        if (yPos) {
          window.scrollTo({
            top: yPos - 50,
            behavior: "smooth",
          });
        }
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [currentProduct]);

  useEffect(() => {
    // Check if product has all necessary modifiers
    const mandatoryModifiersIDs = (availableModifiers || [])
      .filter(
        (modifier) =>
          modifier &&
          modifier.Constraint == ProductModifierConstraints.MANDATORY &&
          modifier.Choices.filter((choice) => choice.DefaultQuantity > 0)
            .length === 0,
      )
      .map((modifier) => modifier.ID);

    const cartModifiersIDs = cartModifiers.map(
      (modifier) => modifier.ProductModifierID,
    );
    setMissingModifiers(
      mandatoryModifiersIDs.filter(
        (modifierID) => !cartModifiersIDs.includes(modifierID),
      ),
    );
    let calculatedModifiersPrice = 0;
    cartModifiers.forEach((modifier) => {
      if (modifier.ProductId) {
        const rawProduct = getProductById(modifier.ProductId);
        if (rawProduct && rawProduct.grossPrice) {
          calculatedModifiersPrice += rawProduct.grossPrice;
        }
      }
    });
    setModifiersPrice(calculatedModifiersPrice);
  }, [cartModifiers, availableModifiers]);

  const scrollToModifier = (modifierId: number) => {
    setModifiersError(true);
    document.getElementById(`modifier-${modifierId}`)?.scrollIntoView({
      block: "center",
      behavior: "smooth",
    });
  };

  const resetProduct = () => {
    if (urlParams.isEdit) {
      urlParams.removeAllParams();
      setCurrentProductAmount(1);
      setCartModifiers([]);
      setComment(undefined);
      setNavigateIndex(-2);
    }
  };

  if (shouldShowLoader) {
    return <Loader isFullScreen />;
  }

  if (!currentProduct) {
    return null;
  }

  return (
    <>
      <DeleteItemsModal
        isOpen={shouldShowDeleteModal}
        header={t("ProductDetailSection.deleteItemsModal.title")}
        message={t("ProductDetailSection.deleteItemsModal.message")}
        showModal={setShouldShowDeleteModal}
        cartLineIds={urlParams.shoppingCartLineIds}
        onConfirm={onDeleteConfirm}
      />

      <LandingImage
        hasBottomGradient
        shouldCompensateBottom
        imageUrl={currentProduct.picture}
      />

      <div ref={scrollList}>
        <BoxList
          onClick={() => shouldShowSuccessModal && navigateBack()}
          id={`boxlist-${currentProduct.id}`}
        >
          {shouldShowSuccessModal && (
            <ItemAddedSuccess
              hideModal={setShouldShowSuccessModal}
              multiple={currentProductAmount > 1}
              isEdit={urlParams.isEdit}
            />
          )}
          <div className={classes.infoBox}>
            <div className={classes.itemTitle} id="product-name">
              {currentProduct.title}
            </div>
            <div className={classes.itemDescription}>
              {currentProduct.description || selectedSubItem?.description}
            </div>
            <div className={classes.itemPrice} id="product-price">
              {t("ProductDetailSection.itemPrice", {
                grossSum:
                  currentProduct.grossPrice || selectedSubItem?.grossPrice || 0,
                formatParams: {
                  grossSum: { currency: "EUR" },
                },
              })}
            </div>
            {currentProduct.subItems &&
              currentProduct.subItems.length > 0 &&
              selectedSubItem && (
                <ProductShapeBlock
                  items={currentProduct.subItems}
                  selectedItem={selectedSubItem}
                  setSelectedItem={setSelectedSubItem}
                />
              )}
          </div>
          {availableModifiers?.map((modifier) => (
            <div className={classes.extrasBlockContainer} key={modifier.ID}>
              <ExtrasBlock
                modifier={modifier}
                cartModifiers={cartModifiers}
                setCartModifiers={setCartModifiers}
                key={modifier.ID}
                hasError={
                  hasModifiersError && missingModifiers.includes(modifier.ID)
                }
              />
            </div>
          ))}
          <Box
            classNames={classNames(
              currentProduct.isAgeRestricted && classes.warningContainer,
            )}
          >
            <SpecialNeedsBlock
              comment={comment}
              setComment={setComment}
              isDisabled={!isKitchenOpen || !canEditShoppingCart}
            />
          </Box>

          <Footer
            hasExtraHeight={
              !isChanged && (currentProduct.isAgeRestricted || urlParams.isEdit)
            }
          >
            {isKitchenOpen ? (
              <>
                {canEditShoppingCart && (
                  <>
                    {urlParams.isEdit && !isChanged && (
                      <Button action={resetProduct} isTransparent>
                        {t(
                          "ProductDetailSection.addSameProductWithOtherModifiersButtonText",
                        )}
                      </Button>
                    )}

                    {!urlParams.isEdit && currentProduct.isAgeRestricted && (
                      <div className={classes.warning}>
                        <div>
                          <Icon
                            url={warningIcon}
                            stroke={color.warningBg}
                            width={22}
                            height={20}
                          />
                        </div>
                        <span>
                          {t("ProductDetailSection.productAlcoholWarning")}
                        </span>
                      </div>
                    )}
                    <div className={classes.buttonContainer}>
                      <AmountButton
                        currentAmount={currentProductAmount}
                        canIncrement={(increase) =>
                          config.appConfig.singleItemMaxQuantity >
                            currentProductAmount + increase &&
                          config.appConfig.singleItemMaxQuantity >
                            otherProductAmountInCart + currentProductAmount &&
                          config.appConfig.cartItemMaxQuantity >
                            shoppingCartTotalItemCount + increase
                        }
                        canDecrement={(amount) =>
                          urlParams.isEdit ? amount > 0 : amount > 1
                        }
                        onAmountChange={setCurrentProductAmount}
                        onTrashAction={
                          urlParams.isEdit
                            ? () => setShouldShowDeleteModal(true)
                            : undefined
                        }
                        isDisabled={
                          currentProductAmount === 0 &&
                          (config.appConfig.singleItemMaxQuantity <=
                            totalProductAmountInCart ||
                            config.appConfig.cartItemMaxQuantity <=
                              shoppingCartTotalItemCount)
                        }
                        isOpen
                        isDebounceDisabled
                      />
                      <div className={classes.buttonText}>
                        {urlParams.isEdit ? (
                          <>
                            {currentProductAmount > 1 && !isChanged ? (
                              <Button
                                action={() => setShouldShowDeleteModal(true)}
                                id="product-delete-btn"
                                className={classes.errorBtn}
                              >
                                {t(
                                  "ProductDetailSection.addProductButton.deleteProductButtonText",
                                )}
                              </Button>
                            ) : (
                              <Button
                                action={updateProduct}
                                isDisabled={
                                  hasReachedMaxQtt ||
                                  !isChanged ||
                                  missingModifiers.length > 0
                                }
                                disabledAction={() => {
                                  if (missingModifiers.length) {
                                    scrollToModifier(missingModifiers[0]);
                                  }
                                }}
                                id="product-update-btn"
                              >
                                <div>
                                  <div
                                    id="product-update-btn-quantity"
                                    className={classes.addToCart}
                                  >
                                    {missingModifiers.length > 0
                                      ? t(
                                          "ProductDetailSection.addProductButton.addRequiredExtrasButtonText",
                                        )
                                      : t(
                                          "ProductDetailSection.addProductButton.updateProductButtonText",
                                        )}
                                  </div>
                                  <div
                                    id="product-update-btn-price"
                                    className={classes.cartPrice}
                                  >
                                    {t(
                                      "ProductDetailSection.addProductButton.productPrice",
                                      {
                                        price: price,
                                        formatParams: {
                                          price: { currency: "EUR" },
                                        },
                                      },
                                    )}
                                  </div>
                                </div>
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            <Button
                              action={() => addToCart(currentProductAmount)}
                              isDisabled={
                                hasReachedMaxQtt || missingModifiers.length > 0
                              }
                              disabledAction={() => {
                                if (missingModifiers.length) {
                                  scrollToModifier(missingModifiers[0]);
                                }
                              }}
                              id="product-add-btn"
                            >
                              <div>
                                <div
                                  id="product-add-btn-quantity"
                                  className={classes.addToCart}
                                >
                                  {missingModifiers.length > 0
                                    ? t(
                                        "ProductDetailSection.addProductButton.addRequiredExtrasButtonText",
                                      )
                                    : t(
                                        "ProductDetailSection.addProductButton.addProductToCartButtonText",
                                        {
                                          quantity: currentProductAmount,
                                        },
                                      )}
                                </div>
                                <div
                                  id="product-add-btn-price"
                                  className={classes.cartPrice}
                                >
                                  {t(
                                    "ProductDetailSection.addProductButton.productPrice",
                                    {
                                      price: price,
                                      formatParams: {
                                        price: { currency: "EUR" },
                                      },
                                    },
                                  )}
                                </div>
                              </div>
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <KitchenClosedFooterContent
                isOpen={false}
                open={() => navigateBack()}
                close={() => navigateBack()}
              />
            )}
          </Footer>
        </BoxList>
      </div>
    </>
  );
};

const ProductPageLoader = () => {
  const { currentProduct } = useProductPageContext();

  if (isStatus(currentProduct, "idle")) {
    return <Loader isFullScreen />;
  }

  if (isStatus(currentProduct, "notFound")) {
    return (
      <ErrorPage
        error={{
          type: "navigation",
          httpCode: 404,
        }}
      />
    );
  }

  return <ProductPageContent currentProduct={currentProduct.state} />;
};

export const ProductPage = () => {
  return (
    <ProductPageContextProvider>
      <ProductPageLoader />
    </ProductPageContextProvider>
  );
};

export default ProductPage;
