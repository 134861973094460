import config from "@constants/config";

export const getImageFromAPI = (
  uri?: string,
  width = 500,
  height = 500,
): string => {
  if (!uri) return "";

  const imageAPI = config.assetConfig.images.resizerServiceUrl;

  return `${imageAPI}/${uri.replace(
    "https://",
    "",
  )}?width=${width}&height=${height}&format=webp`;
};
