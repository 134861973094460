import {
  DiningOption,
  IDiningOptionEnum,
} from "@api/interfaces/configurationLayouts";
import { isPresent } from "@apl-digital/utils";
import { createSelector } from "@reduxjs/toolkit";
import { hasData } from "@store/apiRequestStatusMachine";
import { RootState } from "@store/slices";
import { isStatus } from "@store/utils";

import { selectConfiguration } from "./slice";

export const selectAvailableDiningOptions = createSelector(
  [selectConfiguration],
  (configuration) => {
    if (!hasData(configuration)) {
      return [];
    }

    return configuration.state.DiningOptions.filter(
      (diningOption) => diningOption.IsEnabled,
    );
  },
);

export const selectDiningOption = (
  state: RootState,
  diningOption: IDiningOptionEnum,
) => {
  const configuration = selectConfiguration(state);

  if (!hasData(configuration)) {
    return null;
  }

  return configuration.state.DiningOptions.find(
    (diningOptionItem) => diningOptionItem.ID === diningOption,
  );
};

export const selectIsDiningOptionAvailable = (
  state: RootState,
  diningOption: IDiningOptionEnum,
) => {
  const foundDiningOption = selectDiningOption(state, diningOption);

  return foundDiningOption?.IsEnabled ?? false;
};

export const selectDefaultDiningOption = (
  state: RootState,
): DiningOption | null => {
  const availableDiningOptions = selectAvailableDiningOptions(state);

  if (availableDiningOptions.length === 0) {
    return null;
  }

  const dineInOption = selectDiningOption(state, IDiningOptionEnum.DINE_IN);

  if (isPresent(dineInOption) && dineInOption?.IsEnabled) {
    return dineInOption;
  }

  return availableDiningOptions[0];
};

export const selectIsKitchenOpen = (state: RootState) => {
  const configuration = selectConfiguration(state);
  const availableDiningOptions = selectAvailableDiningOptions(state);

  if (isStatus(configuration, "succeeded")) {
    return (
      configuration.state.IsKitchenOpen && availableDiningOptions.length > 0
    );
  }

  return false;
};

export const selectIsPreOrderAvailable = (
  state: RootState,
  diningOption: IDiningOptionEnum,
) => {
  const configuration = selectConfiguration(state);

  if (!hasData(configuration)) {
    return false;
  }

  return configuration.state.DiningOptions.find(
    (diningOptionItem) => diningOptionItem.ID === diningOption,
  )?.IsPreorderEnabled;
};
