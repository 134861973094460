import { commonLightTheme } from "@theme/themes/common.light";
import { DefaultTheme } from "react-jss";

export const olearysLightTheme: DefaultTheme = {
  ...commonLightTheme,
  blobStorage: {
    ...commonLightTheme.blobStorage,
    brandLogo:
      "https://assets.markus.live/03-order-app/light/logo/logo-olearys.svg",
    burgerMenuLinks: [
      "https://assets.markus.live/00-generic-app/icons/logo/ApolloClub24.svg",
    ],
    // Order Status Page BG
    orderStatusBg:
      "https://assets.markus.live/03-order-app/assets-olearys-ulemiste/light/media/SMS.webp",
  },
  config: {
    ...commonLightTheme.config,
    brandLogoWidth: 104,
    brandLogoHeight: 40,
    sectionsColors: "#FFEFDC;#E8F4F9;#F6F0FF;#FFECEC;#F2F4E0",
  },
} as const;
