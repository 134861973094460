import { commonDarkTheme } from "@theme/themes/common.dark";
import { DefaultTheme } from "react-jss";

export const finnkinoDarkTheme: DefaultTheme = {
  ...commonDarkTheme,
  blobStorage: {
    ...commonDarkTheme.blobStorage,
    brandLogo:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/logo/logo-finnkino.svg",
    // Warning icon
    warningIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons/generic-icons/Caution_Small_thin.svg",
    // Error Page bgImage
    errorPageBg:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/media/ERROR.svg",
    // Order Status BG
    orderStatusBg:
      "https://fk-oa-assets.finnkino.fi/03-order-app/assets-%tenantKey%-%tenantLocation%/dark/media/SMS.webp",
    // Home Page BG image
    homePageBg:
      "https://fk-oa-assets.finnkino.fi/03-order-app/assets-%tenantKey%-%tenantLocation%/dark/media/Landing_background_1290x2796px.webp",
    // Open Burger Menu - Bottom of the Menu
    markusLogo:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/logo/markus_footer_lightgray-color_130x46.svg",
    markusLogoBlack:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/logo/markus_footer_lightgray-color_130x46.svg",
    // Quantity minus icon on Product Page / Cart Page; Active - can be changed (clicked)
    amountMinusActiveIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Remove_thin.svg",
    // Quantity plus icon on Product Page / Cart Page; Active - can be changed (clicked)
    amountPlusActiveIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Add_thin.svg",
    // When Open Modals and Pages that act like a modal - Close button appear in top right corner
    closeModalIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/media/close.svg",
    // Icon to open burger menu
    burgerIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/icons/topbar/TopBar_Hamburger_thin_default.svg",
    // Icon to open burger menu, when having notification (red dot on it appears)
    burgerActiveIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/icons/topbar/TopBar_Hamburger_thin_active.svg",
    // Cart Icon, appears in burger menu for My Orders item
    cartIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons/generic-icons/Shopping_Cart.svg",
    // QR icon that appears inside a text field, when you open QR code manual entry modal
    qrIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Scan_QR_thin.svg",
    //  "https://fk-oa-assets.finnkino.fi/00-generic-app/icons/generic-icons/Scan_QR_thin.svg",
    // Appears in 2 places: Table Number block (located in the right side) on homepage, restaurant info page, cart page; and inside QR manual entry modal for one of the buttons
    scanQrIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Scan_QR_thin.svg",
    // Icon that indicates that field validation passed, located in login form - email field; if email is in valid format - it will display it
    validFieldIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/media/valid_field_green.svg",
    // Displayed on Product page. Product must have modifier.Choices in database
    extraChoiceIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons/generic-icons/Checker_checked_yes_checked-filled_yes.svg",
    orderPendingIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Timing_thin.svg",
    // Status in progress icon
    statusInProgressIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/media/Checker_progress.svg",
    // Status done icon
    statusReadyIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/icons/generic-icons/Checker_checked_yes_checked-filled_yes_dark-yes.svg",
    // Status not done icon
    statusNotReadyIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/icons/generic-icons/Checker_active-yes_selected-no_dark-yes.svg",
    // when API is taking too long to response - loading icon is displayed on a dark placeholder, this icon is spinning via code
    loaderIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/media/loader_small.svg",
    // Displayed when trying to log in and API returned an error (cannot log in)
    failIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/media/fail.svg",
    // Confirm Age Button on Cart Page - disabled state (unchecked)
    checkedWhiteBgSmIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons/generic-icons/Checker_checked_yes_checked-filled_no.svg",
    // Displayed in Burger menu for finished order when KDS system is disabled, in Burger Menu - language selection (current active langage), on order status page when step is completed, in product extras block on product page, in product shape (size) block on product page
    checkedRadioSmIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/icons/generic-icons/Checker_checked-yes_radio_dark-yes.svg",
    // Displayed in Burger Menu - language selection (inactive langage), on order status page when step is incompleted, in product extras block on product page, in product shape (size) block on product page
    emptyRadioSmIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/icons/generic-icons/Checker_active-yes_selected-no_dark-yes.svg",
    // Displayed on Login Success Modal (when you logged in successfully), when you added item to the cart
    successGrayIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/media/Checker_success.svg",
    // Displayed in Restaurant Info Block on homepage, and in Resturant Info Page
    locationIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/icons/generic-icons/Location.svg",
    schedulingIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons/generic-icons/Scheduling_thin.svg",
    timeIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Time_thin.svg",
    // Displayed for warning block, when: login form has error, when age verification is required on product page and cart page
    triangleIconActive:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/icons/generic-icons/Caution_Small_active.svg",
    // Burger Menu - Language menu block
    warningUnchecked:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons/generic-icons/Checker_Alert-yes.svg",
    warningChecked:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons/generic-icons/Checker_checked_yes_checked-filled_no.svg",
    caretRightThinIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Chevron_Forward_thin.svg",
    caretLeftThinIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Chevron_Back_thin.svg",
    caretDownThinIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Expand_thin.svg",
    caretUpThinIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Collapse_thin.svg",
    // In burger Menu - each item has arrow up / down - that's the icon, icon gets rotated by code
    dropMenuIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/icons/generic-icons/Expand.svg",
    // When you press on search button on homepage - it opens search modal - there will be input field where this icon is appeared inside of it
    magnifyingGlassIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/icons/generic-icons/Search_Small.svg",
    // Password input field - show password icon (displayed when password is invisible to user)
    showIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Show_Preview_thin.svg",
    // Password input field - hide password icon (displayed when password is visible to user)
    hideIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Hide_Preview_thin.svg",
    // trash icon is used on cart page - for item row and for the delete all items in the header
    trashIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Delete_thin.svg",
    // In burger menu - logout button has the logout icon
    logoutIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Sign_Out_thin.svg",
    // Displayed on Cart page - user block, the left icon that looks like card
    smallLoyaltyClubCardIcon:
      "https://fk-oa-assets.finnkino.fi/01-loyalty-portal/media/Finnkino_Card_small_62x40.svg",
    tinyLoyaltyClubCardIcon:
      "https://fk-oa-assets.finnkino.fi/01-loyalty-portal/media/Finnkino_Card_small_62x40.svg",
    rotatedLoyaltyClubCardIcon:
      "https://fk-oa-assets.finnkino.fi/01-loyalty-portal/media/Finnkino_Card_big_angled_148x136.svg",
    plusIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons/generic-icons/Add_thin.svg",
    closeIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/light/icons/generic-icons/Close_Big_thin.svg",
    commentIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/small/Comments_thin.svg",
    cameraSwitchIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons/generic-icons/Switch_Camera_thin.svg",
    languageIcon:
      "https://fk-oa-assets.finnkino.fi/00-generic-app/icons-dark-theme/generic-icons/Language_Change_2_thin.svg",
    homePageBanner:
      "https://fk-oa-assets.finnkino.fi/03-order-app/assets-%tenantKey%-%tenantLocation%/dark/media/Landing_background_3840x2160px.webp",
    locationMap:
      "https://fk-oa-assets.finnkino.fi/03-order-app/assets-%tenantKey%-%tenantLocation%/dark/maps/map_%tenantKey%_%tenantLocation%.png",
    businessBanner:
      "https://fk-oa-assets.finnkino.fi/03-order-app/assets-%tenantKey%-%tenantLocation%/dark/media/Business_2940x1470.webp",
    settingsIcon:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/icons/generic-icons/Settings.svg",
    statusBannerImage:
      "https://fk-oa-assets.finnkino.fi/03-order-app/assets-%tenantKey%-%tenantLocation%/dark/media/SMS.webp",
    diningOptionIconDineIn:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/media/Dinein_big.svg",
    diningOptionIconTakeaway:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/media/Takeaway_big.svg",
    spinnerIconSmall:
      "https://fk-oa-assets.finnkino.fi/03-order-app/dark/media/loader_small_24.svg",
  },
  config: {
    ...commonDarkTheme.config,
    brandLogoWidth: 117,
    brandLogoHeight: 45,
    sectionsColors: "#393426",
  },
  color: {
    ...commonDarkTheme.color,
    productRowFeaturedBg: "#FFC800",
    productRowFeaturedText: "#585858",
    toggleSelectedOptionBackground: "#101010",
    locationBoxNavigation: "#FFC800",
    locationBoxHeader: "#E1E1E1",
    signInBackground:
      "linear-gradient(129.21deg, #EBB83E 5.17%, #F2BA4C 94.4%)",
    orderStatusCardBackgroundHighlight: "#989898",
    orderStatusCardInactiveBackgroundColor: "#2D2D2D",
    orderStatusCardPendingBackgroundColor: "#703014",
    orderStatusCardPaidBackgroundColor: "#1E1E1E",
    amountBtnBorder: "#989898",
    amountBtnReversedText: "#F2F2F2",
    buttonText: "#101010",
    buttonBg: "#FFC800",

    modalQrButtonBorder: "#FFC800",
    modalQrButtonIcon: "#FFC800",
    modalQRScannerLink: "#FFC800",
    footerMenuBtnStroke: "#FFC800",
    navbarLoginButtonBg: "#FFC800",
    navbarLoyaltyClub: "#E9E9E9",
    bannerSuccessBackground: "#E1E1E1",
    bannerSuccessTextColor: "#000000",
    bannerErrorBackground: "#1E1E1E",
    bannerErrorTextColor: "#E1E1E1",

    topCategoriesHeadingText: "#E1E1E1",
    subCategoriesHeadingText: "#E1E1E1",
    subCategoriesText: "#E1E1E1",
    subCategoriesTextActive: "#FFFFFF",
    brandLocationTitle: "#E1E1E1",
    errorPageTitle: "#E1E1E1",
    locationInfoHeader: "#E1E1E1",

    qrScannerButtonBackground: "#FFC800",

    buttonTransparentBg: "#1E1E1E",
    buttonTransparentText: "#FFC800",
    buttonTransparentBorder: "#FFC800",
    disabledButtonBg: "#585858",
    disabledButtonText: "#101010",
  },
  font: {
    ...commonDarkTheme.font,
    faceFaces: [
      {
        fontFamily: "'Lexend', sans-serif",
        fontStyle: "normal",
        fontWeight: "300",
        fontDisplay: "swap",
        src: "url('../assets/fonts/Lexend-Light.eot')",
        fallbacks: [
          {
            src: "url('../assets/fonts/Lexend-Light.eot?#iefix') format('embedded-opentype')",
          },
          { src: "url('../assets/fonts/Lexend-Light.woff2') format('woff2')" },
          { src: "url('../assets/fonts/Lexend-Light.woff') format('woff')" },
          { src: "url('../assets/fonts/Lexend-Light.ttf') format('truetype')" },
        ],
      },
      {
        fontFamily: "'Lexend', sans-serif",
        fontStyle: "normal",
        fontWeight: "400",
        fontDisplay: "swap",
        src: "url('../assets/fonts/Lexend-Regular.eot')",
        fallbacks: [
          {
            src: "url('../assets/fonts/Lexend-Regular.eot?#iefix') format('embedded-opentype')",
          },
          {
            src: "url('../assets/fonts/Lexend-Regular.woff2') format('woff2')",
          },
          { src: "url('../assets/fonts/Lexend-Regular.woff') format('woff')" },
          {
            src: "url('../assets/fonts/Lexend-Regular.ttf') format('truetype')",
          },
        ],
      },
      {
        fontFamily: "'Lexend', sans-serif",
        fontStyle: "normal",
        fontWeight: "500",
        fontDisplay: "swap",
        src: "url('../assets/fonts/Lexend-Medium.eot')",
        fallbacks: [
          {
            src: "url('../assets/fonts/Lexend-Medium.eot?#iefix') format('embedded-opentype')",
          },
          { src: "url('../assets/fonts/Lexend-Medium.woff2') format('woff2')" },
          { src: "url('../assets/fonts/Lexend-Medium.woff') format('woff')" },
          {
            src: "url('../assets/fonts/Lexend-Medium.ttf') format('truetype')",
          },
        ],
      },
    ],
    defaultFontStyles: {
      fontFamily: "'Lexend', sans-serif",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
    },
  },
  borderRadius: {
    ...commonDarkTheme.borderRadius,
    button: 16,
    input: 16,
  },
} as const;
