import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Icon from "@base/components/Global/Icon";
import useInterval from "@base/hooks/useInterval";
import { getBlobStorage } from "@theme";

const useStyles = createUseStyles(({ color }) => ({
  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
  outerContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingTop: 48,
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: color.loadingScreenBg,
    zIndex: 100,
    overflowY: "hidden",
  },
  centerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    overflowY: "hidden",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    overflowY: "hidden",
  },
  icon: {
    animation: "$spin 3s infinite linear",
    display: "flex",
  },
}));

export type LoaderProps = {
  wait?: boolean;
  isFullScreen?: boolean;
};

const Loader: React.FC<LoaderProps> = ({
  wait = true,
  isFullScreen = false,
}) => {
  const classes = useStyles();
  const [shouldShowLoader, setShouldShowLoader] = useState(false);

  const intervalTime = wait ? 1000 : 0;

  useInterval(() => setShouldShowLoader(true), intervalTime);

  const { loaderIcon } = getBlobStorage();

  if (!shouldShowLoader) return null;

  if (!isFullScreen) {
    return (
      <div className={classes.centerContainer}>
        <div className={classes.container}>
          <div className={classes.icon}>
            <Icon width={158} height={158} url={loaderIcon} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.outerContainer}>
      <div className={classes.container}>
        <div className={classes.icon}>
          <Icon width={158} height={158} url={loaderIcon} />
        </div>
      </div>
    </div>
  );
};

export default Loader;
