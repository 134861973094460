export enum IDiningOptionEnum {
  DINE_IN = 2,
  TAKE_OUT = 3,
}

export type GetAvailableDatesRequest = {
  diningOption: IDiningOptionEnum;
};

export interface IAvailableDiningDate {
  dtDate: string;
}

export interface IAvailableDiningTime {
  TheatreResourceID: number;
  dttmDelivery: string | null;
}

export type GetAvailableTimesRequest = {
  diningOption: IDiningOptionEnum;
  date: string;
};

export type DiningOption = {
  ID: IDiningOptionEnum;
  Name: string;
  LocalName: string;
  DisplayName: string;
  IsEnabled: boolean;
  IsPreorderEnabled: boolean;
};

export interface ISalesPointConfiguration {
  IsKitchenOpen: boolean;
  ProductSalesLayoutId: number;
  RequiredFormFields?: string[];
  VisibleFormFields?: string[];
  DefaultTableBarcode?: string;
  DiningOptions: DiningOption[];
  KitchenOrderMonitorListWindowInMinutes: number;
}
